<!-- System: STA
    Purpose: In dashboard team member user can see his
          current team members.
-->
<template>
  <div>
    <!-- Team Member Card  -->
    <v-card class="dashboard-boxes" elevation="15">
      <!-- Team Member Details Tootip  -->
      <v-tooltip top color="transparent">
        <template v-slot:activator="{ on, attrs }">
          <img
            :src="$api_url + 'storage/' + member.image"
            @error="
              $event.target.src = require('../../assets/images/no-member.svg')
            "
            class="proj-img mt-2"
            width="100px !important"
            height="100px !important"
            v-bind="attrs"
            v-on="on"
          />
          <h3 class="text-center" v-bind="attrs" v-on="on">
            {{ employee_name }}
          </h3>
        </template>
        <span><UserInfo :member="userInfo" /></span>
      </v-tooltip>

      <!-- Team Member Details -->
      <v-card-text class="justify-center">
        <div>
          Logged Hours:
          <span v-if="member.minutes.hrs <= 9">0{{ member.minutes.hrs }}</span>
          <span v-else>{{ member.minutes.hrs }}</span>
          <span>:</span>
          <span v-if="member.minutes.mins <= 9"
            >0{{ member.minutes.mins }}</span
          >
          <span v-else>{{ member.minutes.mins }}</span>
        </div>
        <div>
          <span> Number of Projects: {{ member.project_count }} </span>
        </div>
        <div>
          <span> Number of Tasks: {{ member.task_count }} </span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import UserInfo from "../includes/UserInfo";
export default {
  name: "DashboardTeamMember",
  props: ["member"],
  components: {
    UserInfo,
  },
  data() {
    return {
      displayTempImg: true,
    };
  },
  computed: {
    /**
     * Return the employee name
     * in reduced form
     */
    employee_name() {
      if (/\s/.test(this.member.name)) {
        return this.member.name
          ? this.member.name.length > 12
            ? this.member.name.match(
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g
              ) !== null
              ? this.member.name
              : this.member.name
                  .match(/\b(\w)/g)
                  .join(" ")
                  .toUpperCase()
                  .slice(0, -1)
                  .concat(" " + this.member.name.split(" ").slice(-1))
            : this.member.name
          : "(No name set yet)";
      } else {
        if (this.member.name.length > 15) {
          if (this.member.name.match(/^\S+$/g)) {
            let concatString = ".....";
            return this.member.name.slice(0, 10).concat(concatString);
          }
        }
        return this.member.name ? this.member.name : "(No name set yet)";
      }
    },
    employee_class() {
      return this.member.name && this.member.name.length > 10;
    },
    /**
     * Return the pirticular info
     * of user
     */
    userInfo() {
      let member = this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == this.member.id
      );
      if (member) {
        return member;
      } else {
        return [];
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.dashboard-boxes {
  height: 296px;
  width: 284px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 22px 40px -25px rgba(55, 72, 154, 0.45) !important;
}
.v-card__text.justify-center > div {
  text-align: left;
  display: block;
  font-weight: 500;
  font-size: 15px;
  padding: 4px;
}
.proj-img {
  border-radius: 100% !important;
  border: none !important;
  margin-left: -2px !important;
}
</style>
